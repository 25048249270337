import { Container } from "./Container";
import backgroundImage from "../images/background-faqs.jpg";

const faqs = [
  [
    {
      question: "Can I sign up for my friend or relative?",
      answer: "Absolutely. You can sign up for a free trial as an emergency contact and invite your friend or relative who will check in every day. They'll need to accept your invitation and sign up for an account."
    }
  ],
  [
    {
      question: "Do you offer free trials?",
      answer:
        "We offer a 14 day free trial,no credit card required upfront for the trial. You can cancel anytime."
    },
    {
      question:
        "Does WellCheck work outside of the US?",
      answer:
        "Yes, we support many English-speaking countries, including Canada, the UK, India, Australia, and Singapore. That means that the senior can be in one country and you and other emergency contacts can be in any of our other supported countries and WellCheck works perfectly!"
    }
  ],
  [
    {
      question: "How does it work?",
      answer: "WellCheck provides automated daily check-ins for seniors living alone. First, we text the senior (or send them a WhatsApp message for our India-based customers) one hour ahead of their scheduled check-in deadline. They can check in by replying to the text. If they don't reply, then WellCheck calls them to check in. If they don't respond to the call, then WellCheck will alert their emergency contacts."
    }
  ]
];

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-md tracking-tight text-slate-700">
            If you can’t find what you’re looking for, email our support team at <a
            href="mailto:support@gowellcheck.com">support@gowellcheck.com</a>&nbsp;
             and someone will get back to you.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
