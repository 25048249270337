import { Container } from "./Container";
import { Logo } from "./Logo";
import { NavLink } from "./NavLink";
import { Link } from "@remix-run/react";

export function Footer() {
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-16">
          <Logo className="mx-auto h-10 w-auto" />
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6 flex-wrap ">
              <NavLink href="#how-it-works">How it Works</NavLink>
              <NavLink href="#international-support">International Support</NavLink>
              {/*<NavLink href="#testimonials">Testimonials</NavLink>*/}
              <NavLink href="#our-story">Our Story</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
              <NavLink rawAnchor={true} href="/privacy-policy.html">Privacy Policy</NavLink>
              <NavLink rawAnchor={true} href="/terms-and-conditions.html">Terms and Conditions</NavLink>
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between mt-6 text-sm text-slate-500 sm:mt-0">
          <div className="flex gap-x-6">
            {/*<Link to="#" className="group" aria-label="WellCheck on X">*/}
            {/*  <svg*/}
            {/*    className="h-6 w-6 fill-slate-500 group-hover:fill-slate-700"*/}
            {/*    aria-hidden="true"*/}
            {/*    viewBox="0 0 24 24"*/}
            {/*  >*/}
            {/*    <path d="M13.3174 10.7749L19.1457 4H17.7646L12.7039 9.88256L8.66193 4H4L10.1122 12.8955L4 20H5.38119L10.7254 13.7878L14.994 20H19.656L13.3171 10.7749H13.3174ZM11.4257 12.9738L10.8064 12.0881L5.87886 5.03974H8.00029L11.9769 10.728L12.5962 11.6137L17.7652 19.0075H15.6438L11.4257 12.9742V12.9738Z" />*/}
            {/*  </svg>*/}
            {/*</Link>*/}
            <Link to="https://www.facebook.com/profile.php?id=61563524577206" className="group my-auto underline" aria-label="WellCheck Facebook Profile">
              Follow us on Facebook
            </Link>
            <Link to="https://www.facebook.com/groups/1178246530120208" className="group underline"
                  aria-label="WellCheck Network Facebook Group">
              Join the Support for Adults with Aging Parents Facebook Group
            </Link>

          </div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} WellCheck. All rights
            reserved.
          </p>
        </div>
      </Container>
    </footer>
  )
}
