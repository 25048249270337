import { Button } from "./Button";
import { Container } from "./Container";
import backgroundImage from "../images/background-call-to-action.jpg";

export function ForFamilyMembers() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            For family members
          </h2>
          <p className="mt-4 text-md tracking-tight text-white">
           Have peace of mind even when your loved ones are far away. Get notified if your loved ones don't check in. Just knowing that they're alright, even if you aren't able to call or text them is a huge relief.
          </p>
          {/*<p>WellCheck even works internationally so if you live in a different time zone, and find it hard to always check on your aging relative, WellCheck has you covered.</p>*/}
          <p className="mt-4 text-md tracking-tight text-white">You can sign up and invite your family member. We'll automatically add you as their emergency contact. When your free trial is over, you can pay for the subscription so they don't have to worry.</p>
          <Button href="/sign-up" color="white" className="mt-10" data-umami-event="Get Started Button" data-umami-event-location="for-family-members">
            Start your free trial
          </Button>
          <p className="text-xs italic pt-2 text-white">No credit card required for trial</p>
        </div>
      </Container>
    </section>
  );
}
