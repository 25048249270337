"use client";

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";

import { Container } from "./Container";
import reminderScreenshot from "../images/screenshots/reminder.png";
import checkinCallScreenshot from "../images/screenshots/check-in-call.png";
import emergencyContactNotificationScreenshot from "../images/screenshots/emergency-contact-notification.png";
import { BellAlertIcon, ChatBubbleBottomCenterIcon, PhoneIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { PhoneFrame } from "~/marketing-site/components/PhoneFrame";

type IconType = React.ForwardRefExoticComponent<React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
  title?: string,
  titleId?: string
} & React.RefAttributes<SVGSVGElement>>;

interface Feature {
  name: React.ReactNode;
  summary: string;
  description: string;
  image: string;
  icon: IconType;
}


const features: Feature[] = [
  {
    name: "Reminder Texts",
    summary: "1. We text your parent a reminder to check in",
    description:
      "We'll text them an hour before the pre-scheduled check-in time. They can simply reply to the text to check in.",
    image: reminderScreenshot,
    icon: ChatBubbleBottomCenterIcon
  },
  {
    name: "Check-in Calls",
    summary: "2. We call them",
    description:
      "If they haven't already checked in by text, we'll call your parent at their scheduled check-in time. They just press 1 to check in.",
    image: checkinCallScreenshot,
    icon: PhoneIcon
  },
  {
    name: "Emergency Contact Notifications",
    summary:
      "3. We let you and any other emergency contacts know if they haven't checked in",
    description:
      "If your parent doesn't check in by the selected deadline, then we let their emergency contacts know so they can make sure your parent is okay.",
    image: emergencyContactNotificationScreenshot,
    icon: BellAlertIcon
  }
];

function Feature({
                   feature,
                   isActive,
                   className,
                   ...props
                 }: React.ComponentPropsWithoutRef<"div"> & {
  feature: Feature
  isActive: boolean
}) {
  const iconFill = isActive ? "white" : "transparent";

  return (
    <div
      className={clsx(className, !isActive && "opacity-75 hover:opacity-100")}
      {...props}
    >
      <div
        className={clsx(
          "w-9 rounded-lg",
          isActive ? "text-slate-900 border-b-4 rounded-none pb-1" : "bg-transparent"
        )}
      >
        <feature.icon fill={iconFill} color="white" />
      </div>
      <h3
        className={clsx(
          "mt-6 text-sm font-medium text-white",
          isActive ? "text-slate-900 font-semibold" : ""
        )}
      >
        {feature.name}
      </h3>
      <p className="mt-2 font-display text-xl text-white">
        {feature.summary}
      </p>
      <p className="mt-4 text-sm text-white">{feature.description}</p>
    </div>
  );
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.summary}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <PhoneFrame className="z-10 mx-auto w-full max-w-[375px]">
              <div className="-mt-[22px]">
                <img
                  className="w-full"
                  src={feature.image}
                  alt=""
                  sizes="52.75rem"
                />
              </div>
            </PhoneFrame>
          </div>
        </div>
      ))}
    </div>
  );
}

function FeaturesDesktop() {
  return (
    <TabGroup className="hidden lg:mt-20 lg:grid lg:grid-cols-3 lg:gap-x-4">
      {({ selectedIndex }) => (
        <>
          <div className="col-span-1">
            <TabList className="grid grid-rows-3 gap-y-8">
              {features.map((feature, featureIndex) => (
                <Feature
                  key={feature.summary}
                  feature={{
                    ...feature,
                    name: (
                      <Tab className="ui-not-focus-visible:outline-none">
                        <span className="absolute inset-0" />
                        {feature.name}
                      </Tab>
                    )
                  }}
                  isActive={featureIndex === selectedIndex}
                  className="relative"
                />
              ))}
            </TabList>
          </div>
          <div className="col-span-2 relative overflow-hidden">
            <TabPanels className="flex transition-transform duration-500 ease-in-out"
                       style={{ transform: `translateX(-${selectedIndex * 100}%)` }}>
              {features.map((feature, featureIndex) => (
                <TabPanel
                  static
                  key={feature.summary}
                  className={clsx(
                    "px-5 w-full shrink-0 transition-opacity duration-500 ease-in-out",
                    featureIndex !== selectedIndex ? "opacity-0 invisible" : "opacity-100 visible"
                  )}
                >
                  <div className="w-full overflow-hidden">
                    <PhoneFrame className="z-10 mx-auto w-full max-w-[375px]">
                      <div className="-mt-[22px]">
                        <img
                          className="w-full"
                          src={feature.image}
                          alt=""
                          sizes="52.75rem"
                        />
                      </div>
                    </PhoneFrame>
                  </div>
                </TabPanel>
              ))}
            </TabPanels>
            <div className="pointer-events-none absolute" />
          </div>
        </>
      )}
    </TabGroup>
  );
}

export function SecondaryFeatures() {
  return (
    <section
      id="how-it-works"
      aria-label="How it works"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32 bg-blue-600"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            How it works
          </h2>
          {/*<p className="mt-4 text-lg tracking-tight  text-white">*/}
          {/*  Because you’d probably be a little confused if we suggested you*/}
          {/*  complicate your everyday business tasks instead.*/}
          {/*</p>*/}
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  );
}
